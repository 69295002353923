"use client";

import { ReactNode, useEffect } from "react";
import {
  Box,
  BoxProps,
  Divider,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Stack,
  Typography
} from "@mui/material";

import { ResponsiveStyle } from "theme/theme";

import { CloseButton } from "../../component/button";

export interface MhcModalProps extends ModalProps {
  additionalHeaderContent?: ReactNode | null;
  title?: string;
  caption?: string | null;
  handleClose: () => void;
  useDivider?: boolean;
  width?: ResponsiveStyle;
  height?: ResponsiveStyle;
  headerStyle?: BoxProps["sx"];
  contentStyle?: BoxProps["sx"];
  modalPaperStyle?: PaperProps["sx"];
  footerContent?: ReactNode;
}

export const MhcModal: React.FC<MhcModalProps> = ({
  children,
  additionalHeaderContent = null,
  title,
  caption,
  handleClose,
  useDivider,
  width = "600px",
  headerStyle = {},
  contentStyle = {},
  modalPaperStyle = {},
  footerContent = null,
  ...props
}) => {
  const style: BoxProps["sx"] = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    maxWidth: "95vw",
    bgcolor: "background.paper",
    border: "0px transparent #000",
    py: {
      xs: 3,
      md: 4
    },
    ...modalPaperStyle
  };

  const id = props["aria-labelledby"];

  useEffect(() => {
    if (props.open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open"); // Cleanup on unmount
    };
  }, [props.open]);

  return (
    <Modal {...props} onClose={handleClose} sx={{ ...props.sx }} disableScrollLock={false}>
      <Paper elevation={10} sx={{ ...style }}>
        <Box
          component={"header"}
          id={`modal-${id ?? ""}-header`}
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            gap: { xs: 1, md: 4 },
            top: 0,
            bottom: "auto",
            pb: 2,
            px: { xs: 2, md: 4 },
            ...headerStyle
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: undefined, md: "center" }}
            flexGrow={1}
            gap={2}
          >
            <Stack gap={0.5}>
              {title && (
                <Typography id={id} variant="h3" component="h4" data-testid="modal-title">
                  {title}
                </Typography>
              )}
              {caption && (
                <Typography variant="caption" component="p" data-testid="modal-caption">
                  {caption}
                </Typography>
              )}
            </Stack>
            {additionalHeaderContent}
          </Stack>
          <CloseButton onClick={handleClose} sx={{ marginLeft: "auto" }} />
        </Box>
        {useDivider === true && <Divider sx={{ mx: { xs: 2, md: 4 } }} />}
        <Box
          sx={{
            maxHeight: "75vh",
            overflow: "auto",
            px: { xs: 2, md: 4 },
            pt: { xs: 2, md: 4 },
            ...contentStyle,
            overscrollBehavior: "contain"
          }}
        >
          {children}
        </Box>
        {footerContent}
      </Paper>
    </Modal>
  );
};
